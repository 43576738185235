import webconfig from "../webjson/webconfig.json";
// class LogoInfo {
//     constructor() {
//         this.logoSrc = webconfig.logoinfo.logosrc
//         this.webName = webconfig.logoinfo.webname
//         this.altTitle = webconfig.logoinfo.alttitle
//         this.logoWidth = webconfig.logoinfo.logowidth
//         this.logoHeight = webconfig.logoinfo.logoheight
//         this.logoToUrl = webconfig.logoinfo.logoToUrl
//     }
// }

class MenuInfo {
    constructor() {
        this.menulist = webconfig.menuList
    }
}

class DataInit{
    static getData(data) {
        for (const key in webconfig[data.$options.name]) {
            data[key]  = webconfig[data.$options.name][key];
        }
    }

}
export {  MenuInfo ,DataInit}
