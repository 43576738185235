<template>
  <main-layout>
    <div class="mainDIV">
      <img :src="getImgurl(lostimg)" width="400" alt="" />
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";

export default {
  data() {
    return {
      lostimg: "assets/img/404.png",
    };
  },
  components: {
    MainLayout,
  },
  methods: {
    getImgurl(src) {
      if (src.length > 0) {
        if (src.indexOf("http") == 0) {
          return src;
        }
        if (src.indexOf("http") == -1) {
          return require("@/" + src);
        }
      } else {
        return src;
      }
    },
  },
};
</script>
<style>
.mainDIV {
  padding: 200px;
  margin: 0 auto;
  text-align: center;
  height: 800px;
  background: #FF8200;
}
</style>
