<template>
  <div class="row">
    <div class="col-12 col-s-12" >
      <img 
        :src="getImgurl(logo[index].logosrc)"
        :width="logo[index].logowidth"
        :height="logo[index].logoheight"
        :title="logo[index].alttitle"
      />
    </div>
    <!-- <div class="col-6 col-s-6" style="padding:0;">
      <a class="webname" :href="logoToUrl">
        {{ logo[index].webName }}
      </a>
    </div> -->
  </div>
</template>

<script>
import { DataInit } from "@/assets/common/config.js";
export default {
  name:"logoinfo",
  data() {
    return {
      logo:[],
      index:0,
    };
  },
  created() {
    DataInit.getData(this);
  },
  methods: {
    getImgurl(src) {
      if (src.length > 0) {
        if (src.indexOf("http") == 0) {
          return src;
        }
        if (src.indexOf("http") == -1) {
          return require("@/" + src);
        }
      } else {
        return src;
      }
    },
  },
};
</script>

<style scoped>
.logo * {
  display: inline;
}
.webname {
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 40px;
  color:#fff;
}

</style>
