<template>
  <main-layout>
    <Banner :banners="banners" />

    <div class="mainblock" style="margin-top: -160px !important; z-index: 99">
      

      <Card :card="cardA" :showIcon="true" />

      <div class="padding-md text-align-center">
        <!-- <button class="button primary button-s-md button-md" type="">
          立即使用
        </button> -->
        <h2>{{ titleA.title }}</h2>
        <p>{{ titleA.content }}</p>
      </div>

      <Card :card="cardB" :showImg="true" />
     

      <div class="padding-md text-align-center">
        <h2>{{ titleB.title }}</h2>
        
        <p>{{ titleB.content }}</p>
        <a
          href="http://www.baidu.com"
          class="button primary button-s-md button-md"
          type=""
        >
          开始试用</a
        >
      </div>

      <div class="padding-md text-align-center row">
       
        <div class="col-4 col-s-12" v-for="list in datas" :key="list.index">
          <h1 class="primay">{{list.sum}}</h1>
          <p>{{list.name}}</p>
        </div>
      </div>

      <div>
        <h3>常见问题</h3>
        <Collapes :collapsList="collapesListA" />
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import Card from "../components/card.vue";
import Banner from "../components/banner";
import Collapes from "../components/collapes.vue";
import { DataInit } from "../assets/common/config.js";

export default {
  name:"download",
  data() {
    return {
      banners:[],
      cardA:[],
      cardB: [],
      titleA: [],
      titleB: [],
      titleD: [],
      collapesListA:[],
      datas:[]
    };
  },
  components: {
    MainLayout,Card,
    Collapes,
    Banner
  },
  created() {
    DataInit.getData(this)
  },
   methods: {
    
  }
  
};
</script>
