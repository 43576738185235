<template>
  <a
    :href="href"
    @click.prevent="go"
    :class="{ active: isActive }"
  >
    <slot></slot>
  </a>
</template>

<script>
import routes from '../routes'

export default {
  props: {
    href: {
      type:String,
      required: true
    }
  },
computed: {
      isActive () {
        return this.href === this.$root.currentRoute
      }
    },
  methods: {
    go () {
      this.$root.currentRoute = this.href
      window.history.pushState(null, routes[this.href], this.href)
    }
  }
}
</script>
