<template>
  <main-layout>
    <Banner :banners="banners" />

    <div class="mainblock" style="margin-top: -200px !important; z-index: 99">
      <div class="row">
        <ul class="carousel">
          <li v-for="(list, index) in blockA" :key="index">
            <img class="width100" :src="getImgurl(list.imgurl)" alt="" />
          </li>
        </ul>
      </div>
      <Card :card="cardA" :showIcon="true" style="padding-top: 60px" />
      <div class="padding-md text-align-center">
        <h2>{{ titleB.title }}</h2>
        <p>{{ titleB.content }}</p>
      </div>
      <!-- <Tab :tabInfo="tablist"  tabStyle="horizontal" /> -->

      <!-- <div class="padding-md text-align-center">
        <h2>{{ titleA.title }}</h2>
        <a
          href="http://www.baidu.com"
          class="button primary button-s-md button-md"
          type=""
        >
          开始试用</a
        >
        <p>{{ titleA.content }}</p>
      </div> -->
      <div class="padding-md text-align-center row">
        <!-- <h2>{{ titleD.title }}</h2> -->
        <div class="col-4 col-s-12" v-for="list in datas" :key="list.index">
          <h1 class="primay">{{ list.sum }}</h1>
          <p>{{ list.name }}</p>
        </div>
      </div>
      <!-- <Card :card="cardB" :showImg="true" /> -->

      <!-- <div>
        <h3>常见问题</h3>
        <Collapes :collapsList="collapesListA" />
      </div> -->
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import Banner from "../components/banner.vue";
import Card from "../components/card.vue";
// import Tab from "../components/tab.vue";
// import Collapes from "../components/collapes.vue";
import { DataInit } from "../assets/common/config.js";

export default {
  name: "product",
  data() {
    return {
      banners: [],
      blockA: [],
      cardA: [],
      cardB: [],
      titleA: [],
      titleB: [],
      titleD: [],
      tablist: [],
      collapesListA: [],
      datas: [],
      blockAimg: [],
    };
  },
  methods: {
    getImgurl(src) {
      if (src.length > 0) {
        if (src.indexOf("http") == 0) {
          return src;
        }
        if (src.indexOf("http") == -1) {
          return require("@/" + src);
        }
      } else {
        return src;
      }
    },
  },
  computed: {},
  components: {
    MainLayout,
    // Tab,
    // Collapes,
    Banner,
    Card,
  },
  created() {
    DataInit.getData(this);
    // this.imgInit()
  },
};
</script>
<style>
.width100 {
  width: 100%;
}
.carousel {
  list-style: none;
  padding: 0;
  margin: 0;
}
.carousel li {
  display: inline;
}
</style>
