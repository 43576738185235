<template>
  <div class="footerblock-bg">
    <div class="footerblock padding-sm">
      <div class="row text-align-center">
        <div class="col-4 col-s-6">
          <ul class="gray ul-horizontal">
            <li style="text-align:left;" v-for="list in serviceInfo" :key="list.index">
              <a :href="list.herf">{{ list.title }}</a>
            </li>
          </ul>
        </div>
        <div class="col-4 col-s-6">
          <ul class="gray ul-horizontal">
            <li style="text-align:left;" v-for="list in companyInfo" :key="list.index">
              <a :href="list.herf">{{ list.title }}</a>
            </li>
          </ul>
        </div>
        <div class="col-4 col-s-12">
          <div class="row">
            <div style="text-align:left;"
              class="col-12 col-s-12"
              v-for="list in wxQrcode"
              :key="list.index"
            >
              <img :src="getImgurl(list.qrCodeimg)" width="120" alt="" />
              <p style="padding-top:10px;">{{ list.name }}</p>
            </div>
          </div>
        </div>
        <div class="col-12 col-s-12 gray" style="margin-top:10px;">
          ©2018-{{getYear}} www.alwayspay.co All Rights Reserved 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DataInit } from "../assets/common/config.js";
export default {
  name: "Footer",
  data() {
    return {
      serviceInfo: [],
      companyInfo: [],
      wxQrcode: [],
    };
  },
  //部件
  components: {},
  //静态
  props: {},
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {
    getYear(){
      let date = new Date()
      var year = date.getFullYear()
      return (year)
    },

  },
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {
    getImgurl(src) {
      
      if (src.length > 0) {
        if (src.indexOf("http") == 0) {
          return src;
        }
        if (src.indexOf("http") == -1) {
          return require("@/" + src);
        }
      } else {
        return src;
      }
    },
  },
  //请求数据
  created() {
       DataInit.getData(this)
  },
  mounted() {},
};
</script>

<style>
</style>
