<template>
  <div class="row">
    <div class="">
      <ul
        class="cllapse col-6 col-s-12"
        v-for="(list, index) in collapsList"
        :key="list.index"
      >
        <li @click="handlerClick(index)">
          <h4 class="">{{ list.question }}<i style="float:right;"> > </i></h4>
          <p v-show="isActive == index">{{ list.answer }}</p>
          
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    collapsList: Array,
  },

  data() {
    return {
      isActive: -1,
      questionList: [
        {
          question: "33存在哪里？安全吗？",
          answer:
            "回答你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。",
        },
        {
          question: "44存在哪里？安全吗？",
          answer:
            "回答你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。",
        },
        {
          question: "4555存在哪里？安全吗？",
          answer:
            "回答你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。",
        },
        {
          question: "66666哪里？安全吗？",
          answer:
            "回答你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。",
        },
        {
          question: "8888在哪里？安全吗？",
          answer:
            "回答你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。",
        },
        {
          question: "999存在哪里？安全吗？",
          answer:
            "回答你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。你会得到一个统一的平台，能够轻松运营您的业务。现在，您可以更专注于服务了。",
        },
      ],
    };
  },
  components: {},
  computed: {},
  methods: {
    handlerClick(index) {
      if (this.isActive == index) {
        this.isActive = -1;
      } else {
        this.isActive = index;
      }
    },
    reSetActive() {
      this.isActive = -1;
    },
  },
  //请求数据
  created() {},
  mounted() {},
};
</script>

<style scoped>
</style>
