<template>
  <div class="menu-container headerblock">
    <Logo />ssss
    <Nav />
  </div>
</template>

<script>
import Nav from "./nav.vue";
import Logo from "./logo.vue";
export default {
  //部件
  components: {
        Nav,Logo
  },

  props: {},
  //对象内部的属性监听，也叫深度监听
  watch: {},
  //属性的结果会被缓存，除非依赖的响应式属性变化才会重新计算。主要当作属性来使用；
  computed: {},
  //方法表示一个具体的操作，主要书写业务逻辑；
  methods: {},
  //请求数据
  created() {},
  mounted() {},
};
</script>

<style scoped>
</style>
