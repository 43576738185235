<template>
  <main-layout>
    <div class="" :style="bannerBg">
      
    </div>

    <div
      class="mainblock bg-white"
      style="margin-top: -150px !important; z-index: 99; border-radius:10px;background:#FCFCFC;"
    >
      <div class="row">
        <div class="col-2 col-s-12" style="padding-right:20px !important;">
          <ul class="sidemenu">
            <li :class="sideIndex == index ? 'active' : ''" v-for="(list,index) in info" :key="index" @click="sideclick(index)">{{list.name}}</li>
          </ul>
        </div>
        <div class="col-10 col-s-12">
          <h2 class="titleH">{{info[sideIndex].name}}</h2>
          <div class="content" v-html="info[sideIndex].htmlcontent">
             
          </div>
          
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import { DataInit } from "../assets/common/config.js";

export default {
  name:"solution",
  data() {
    return {
      info:[],
      sideIndex:0,
      bannerBgImgurl:'',
      bannerBg:{
        background:'',
        backgroundRepeat:'no-repeat',
        backgroundSize:'auto 100%',
        backgroundPosition:'left top',
        backgroundAttachment: 'fixed',
        transitionDuration: 0.4+'s',
        height:600+'px',

      }
    };
  },
  components: {
    MainLayout,
  },
  methods:{
    // bannerBgInit(){
    //   this.bannerBgImgurl = this.getImgurl(this.info[this.sideIndex].banner)
    //   console.log(this.bannerBgInit,this.bannerBg.background);
    // },
    sideclick(index){
      
      this.sideIndex = index;
      // this.bannerBgInit()
      this.bannerBg.background = 'url('+ this.getImgurl(this.info[this.sideIndex].banner) +')'
    },
     getImgurl(src) {
      if (src.length > 0) {
        if (src.indexOf("http") == 0) {
          return src;
        }
        if (src.indexOf("http") == -1) {
          return require("@/" + src);
        }
      } else {
        return src;
      }
    },
    parseUrl(){
      var url=location.href;
      var i=url.indexOf('?');
      if(i==-1)return;
      var querystr=url.substr(i+1);
      var arr1=querystr.split('&');
      var arr2=new Object();
      for  (i in arr1){
          var ta=arr1[i].split('=');
          arr2[ta[0]]=ta[1];
      }
      return arr2;
    }
  },
  created(){
    let post = this.parseUrl();
    if(post && Object.prototype.hasOwnProperty.call(post, "id") ){
      this.sideIndex = post.id;
    }
     DataInit.getData(this)
    
     this.bannerBg.background = 'url('+ this.getImgurl(this.info[this.sideIndex].banner) +')'
    //  console.log(this.getImgurl(this.info[this.sideIndex].banner));
  },
};
</script>
<style>
/* p{line-height:20px; padding:20px;} */
.titleH{
  padding:10px;
  margin:10px;
  border-bottom:solid 1px var(--colorCBCBCB);
  text-align: center;
}
.sidemenu{
  padding:0;
  margin:0;
  list-style: none;
}
.sidemenu li{
  border-bottom:solid 1px var(--colorCBCBCB);
  padding:6px;
  line-height: 20px;
  margin:4px;
  cursor:pointer;
}
.sidemenu li.active {
    font-size: 14px;
    color: var(--colorpopaul);
    border-bottom:solid 2px var(--colorpopaul);

}

.sidemenu li:hover:not(.active) {
    /* background-color: var(--colorCBCBCB);
    color: var(--color333); */
    font-size: 14px;
    color: var(--colorpopaul);
}
.content{
  padding:20px;
  line-height:20px;

}
.content h2,h3{
  padding:20px;
  line-height:30px;

}
.content p{
  line-height:20px;
  padding:10px;
}
.content img{width:100%;
margin:40px 0;}
</style>
