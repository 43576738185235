<template>
  <main-layout>
    <Banner :banners="banners" />

    <div class="mainblock" style="margin-top:-100px;z-index: 99; ">
      <div class="row" >
        <div class="col-4 col-s-12" v-for="list in cardA" :key="list.index" >
          <div class="carditem height224">
            <i class="icon-sm icon-primary" :class="list.icon"></i>
            <h3>{{ list.title }}</h3>
            <p>{{ list.content }}</p>
          </div>
        </div>
      </div>

      <div class="padding-md text-align-center">
        <button class="button primary button-s-md button-md" type="">
          立即使用
        </button>
        <h2>{{ titleA.title }}1111</h2>
        <p>{{ titleA.content }}</p>
      </div>

      <div class="row">
        <div class="col-4 col-s-12" v-for="list in cardB" :key="list.index">
          <div class="carditem">
            <i class="icon-sm icon-primary" :class="list.icon"></i>
            <h3>{{ list.title }}</h3>
            <p>{{ list.content }}</p>
          </div>
        </div>
      </div>

      <div class="padding-md text-align-center">
        <h2>{{ titleB.title }}</h2>
        <p>{{ titleB.content }}</p>
      </div>
      <Tab :tabInfo="tablist" />

      <div class="padding-md text-align-center row">
        <h2>{{ titleD.title }}</h2>
        <div class="col-4 col-s-12" v-for="list in datas" :key="list.index">
          <h1 class="primay">{{list.sum}}</h1>
          <p>{{list.name}}</p>
        </div>
      </div>

      <div>
        <h3>常见问题</h3>
        <Collapes :collapsList="collapesListA" />
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "../layouts/Main.vue";
import Tab from "../components/tab.vue";
import Banner from "../components/banner";
import Collapes from "../components/collapes.vue";
import { DataInit } from "../assets/common/config.js";

export default {
  name:"ecological",
  data() {
    return {
      banners: [],
      cardA:[],
      cardB: [],
      titleA: [],
      titleB: [],
      titleD: [],
      tablist:[],
      collapesListA:[],
      datas:[]
    };
  },
  components: {
    MainLayout,Tab,Collapes,Banner
  },
  created() {
    DataInit.getData(this)
  },
   methods: {
    
  }
  
};
</script>
