export default {
  '/': 'Home',
  '/about': 'About',
  '/solution': 'Solution',
  '/product': 'Product',
  '/ecological': 'Ecological',
  '/download': 'Download',


}
