<template>
  <div>
    <ul>
    <li><v-link href="/">Home</v-link></li>
    <li><v-link href="/about">About</v-link></li>
    <li><v-link href="/foo">Under Construction</v-link></li>
  </ul>
    <!-- <ul class="navblock" style="float: right">
      <li
        v-for="nav in menulist"
        :key="nav.index"
        :class="[nav.isActive ? 'active' : '']"
      >
      <v-link href="/about" >{{ nav.menuTitle }}</v-link>
      </li>
    </ul> -->
  </div>
</template>

<script>
import { MenuInfo } from "../assets/common/config.js";
import VLink from "./VLink";
let menu = new MenuInfo();


export default {
  data() {
    return {
      menulist: menu.menulist,

    };
  },
  components: {
     VLink
  },
  created() {},
  methods: {
    
  },
};
</script>

<style scoped>
.logo * {
  display: inline;
}
.webname {
  font-size: 1.6rem;
}
</style>
