<template>
  <div class="row">
    <div :class="tabClass">
      <ul :class="tabArrangement">
        <li
          v-for="(tab, index) in tabInfo"
          :key="tab.key"
          @click="tabclick(index)"
          :class="tabDefault == index ? 'active' : ''"
        >
          {{ tab.tabname }}
        </li>
      </ul>
    </div>
    <div :class="tabContent">
      <div class="tabcontents">
        <img :src="getImgurl(tabInfo[tabDefault].imgurl)" alt=""  />
        <p>
          {{ tabInfo[tabDefault].content }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabInfo: Array,
    tabStyle:String,
    default:{
      tabstyle:"col-3 col-s-4"
    }
  },

  data() {
    return {
      tabDefault: 0,
      tabId: 0,
      tabClass:'',
      tabArrangement:'',
      tabContent:''
    };
  },
  components: {},
  computed: {},
  methods: {
    tabclick(index) {
      this.tabDefault = index;
    },
    getImgurl(src) {
      if (src.length > 0) {
        if (src.indexOf("http") == 0) {
          return src;
        }
        if (src.indexOf("http") == -1) {
          return require("@/" + src);
        }
      } else {
        return src;
      }
    },
  },
  //请求数据
  created() {
    if(this.tabStyle == 'horizontal') {
      this.tabClass = "col-12 col-s-12"
      this.tabArrangement = 'tabHorizontal'
      this.tabContent = 'col-12 col-s-12'
    }
    if(this.tabStyle == 'vertical'){
      this.tabClass = "col-3 col-s-3"
      this.tabArrangement = 'tabVertical'
      this.tabContent = 'col-9 col-s-9'
    }
  },
  mounted() {},
};
</script>

<style>

.tabVertical {
    list-style: none;
    padding: 0;
    margin: 0;
}

.tabVertical li {
    display: block;
    border: solid 1px var(--colorCBCBCB);
    border-radius: 4px;
    padding: 8px 10px;
    text-align: center;
    margin: 6px;
    cursor: pointer;
}
.tabVertical li.active {
    background-color: var(--colorpopaul);
    color: white;
}

.tabVertical li:hover:not(.active) {
    background-color: var(--colorCBCBCB);
    color: var(--color333);
}

.tabHorizontal {
    list-style: none;
    padding: 0;
    margin: 0;
}
.tabHorizontal li {
    display:inline-block;
    border: solid 1px var(--colorCBCBCB);
    border-radius: 4px;
    padding: 8px 10px;
    text-align: center;
    margin: 10px ;
    line-height: 20px;;
    cursor: pointer;
}



.tabHorizontal li.active {
    background-color: var(--colorpopaul);
    color: white;
}

.tabHorizontal li:hover:not(.active) {
    background-color: var(--colorCBCBCB);
    color: var(--color333);
}
.tabcontents{
  text-align: center;
}
.tabcontents p{
  line-height:26px;
  font-size:1.3rem;
  padding:20px;
  text-align: left;
}
.tabcontents img{
  width:90%;
  margin:10px auto;
  
}
</style>
